import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-ec6ea16a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "navi-groups" }
const _hoisted_2 = { class: "navi-button" }
const _hoisted_3 = { class: "navi-button" }
const _hoisted_4 = { class: "navi-button" }
const _hoisted_5 = { class: "navi-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, { to: "/" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, _toDisplayString(`${!$setup.chs ? 'Home' : '主页' }`), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_router_link, { to: "/server" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, _toDisplayString(`${!$setup.chs ? 'Server' : '服务' }`), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_router_link, { to: "/documentation" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, _toDisplayString(`${!$setup.chs ? 'Documentation' : '文档' }`), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_router_link, { to: "/contact" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_5, _toDisplayString(`${!$setup.chs ? 'Contact' : '联系' }`), 1)
      ]),
      _: 1
    })
  ]))
}