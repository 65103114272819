<template>
  <router-link to="/">
    <div id="logo"/>
  </router-link>
</template>

<script>
export default {
  name: 'HeaderLogoView'
}
</script>

<style scoped>
  #logo {
    background-image: url("../../assets/logo.png");
    width: 42px;
    height: 46px;
    cursor: pointer;
  }
</style>
