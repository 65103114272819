<template>
  <div id="navi-groups">
    <router-link to="/">
      <div class="navi-button">
        {{ `${!chs ? 'Home' : '主页' }` }}
      </div>
    </router-link>
    <router-link to="/server">
      <div class="navi-button">
        {{ `${!chs ? 'Server' : '服务' }` }}
      </div>
    </router-link>
    <router-link to="/documentation">
      <div class="navi-button">
        {{ `${!chs ? 'Documentation' : '文档' }` }}
      </div>
    </router-link>
    <router-link to="/contact">
      <div class="navi-button">
        {{ `${!chs ? 'Contact' : '联系' }` }}
      </div>
    </router-link>
  </div>
</template>

<script lang="ts">
import { useRootStore } from '@/stores/store'
import { storeToRefs } from 'pinia'

export default {
  name: 'HeaderNaviView',
  setup () {
    const root = useRootStore()
    const { chs } = storeToRefs(root)

    return {
      chs
    }
  }
}
</script>

<style scoped>

a {
  display: flex;
  align-items: center;
  height: 100%;
  text-decoration: none;
  margin-left: 5vw;
  justify-content: center;
}

a.active {
  border-bottom: 2px solid #A81726
}

#navi-groups {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 1vw;
  width: 85%;
  height: 100%;
}

.navi-button {
  /*width: 66px;*/
  font-family: Source Han Sans CN-Regular, serif;
  font-weight: 400;
  font-size: 16px;
  color: #001333;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.active .navi-button {
  font-family: Source Han Sans CN-Bold, serif;
  font-weight: bold;
  color: #A81726;
}

</style>
