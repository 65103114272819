import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderViewIndex = _resolveComponent("HeaderViewIndex")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_FootIndexView = _resolveComponent("FootIndexView")!
  const _component_LoaderView = _resolveComponent("LoaderView")!
  const _component_MaskView = _resolveComponent("MaskView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_HeaderViewIndex),
    _createVNode(_component_router_view),
    _createVNode(_component_FootIndexView),
    ($setup.running)
      ? (_openBlock(), _createBlock(_component_MaskView, { key: 0 }, {
          content: _withCtx(() => [
            _createVNode(_component_LoaderView)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}