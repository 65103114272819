<template>
  <HeaderViewIndex />
  <router-view/>
  <FootIndexView/>
  <MaskView v-if="running">
    <template v-slot:content>
      <LoaderView />
    </template>
  </MaskView>
</template>

<script lang="ts">
import HeaderViewIndex from '@/components/HeaderView/HeaderIndexView.vue'
import FootIndexView from '@/components/FootView/FootIndexView.vue'
import { useRootStore } from '@/stores/store'
import LoaderView from '@/components/LoaderView.vue'
import MaskView from '@/components/MaskView.vue'
import { computed, onMounted, onUnmounted } from 'vue'
import { storeToRefs } from 'pinia'

export default {
  components: { FootIndexView, HeaderViewIndex, LoaderView, MaskView },
  setup () {
    const root = useRootStore()

    const running = computed(() => {
      return root.status === 'running'
    })

    return {
      running
    }
  }
}
</script>

<style>

@font-face {
  font-family: "Source Han Sans CN-Regular";
  /*src: url("./assets/fonts/SourceHanSansCN-Regular.otf");*/
  src: url("https://confgem.cmdrg.com/assets/fonts/SourceHanSansCN-Regular.otf");
}

@font-face {
  font-family: "Source Han Sans CN-Bold";
  /*src: url("./assets/fonts/SourceHanSansCN-Bold.otf");*/
  src: url("https://confgem.cmdrg.com/assets/fonts/SourceHanSansCN-Bold.otf");
}

body {
  border: 0;
  margin: 0;
}

#app {
  position: relative;
}

/*html {*/
/*  animation: 0.2s flash;*/
/*}*/

/*@keyframes flash*/
/*{*/
/*  0% {*/
/*    transform:scale(1);*/
/*    opacity: 1;*/
/*  }*/

/*  100% {*/
/*    transform:scale(1.2);*/
/*    opacity: 0;*/
/*  }*/
/*}*/

</style>
