<template>
  <div id="header">
    <div class="header-container">
      <div class="fs">
        <header-logo-view />
        <header-navi-view/>
      </div>
      <header-switches-view />
    </div>
  </div>
</template>

<script lang="ts">
import HeaderNaviView from '@/components/HeaderView/HeaderNaviView.vue'
import HeaderLogoView from '@/components/HeaderView/HeaderLogoView.vue'
import HeaderSwitchesView from '@/components/HeaderView/HeaderSwitchesView.vue'
export default {
  name: 'HeaderViewIndex',
  components: { HeaderSwitchesView, HeaderLogoView, HeaderNaviView }
}
</script>

<style scoped>

#header {
  position: fixed;
  width: 100%;
  height: 64px;
  z-index: 999;
}

#header:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  background: #FFFFFF;
  z-index: -1;
  box-shadow: 0px 4px 6px 1px rgba(0,0,0,0.1);
  border-radius: 0px 0px 0px 0px;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 90%;
  margin-left: 6.25vw;
}

.fs {
  display: flex;
  height: 100%;
  align-items: center;
  width: 55%;
}

</style>
