import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-1498f994"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "header" }
const _hoisted_2 = { class: "header-container" }
const _hoisted_3 = { class: "fs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_logo_view = _resolveComponent("header-logo-view")!
  const _component_header_navi_view = _resolveComponent("header-navi-view")!
  const _component_header_switches_view = _resolveComponent("header-switches-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_header_logo_view),
        _createVNode(_component_header_navi_view)
      ]),
      _createVNode(_component_header_switches_view)
    ])
  ]))
}