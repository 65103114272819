import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0cfbd326"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "description-container" }
const _hoisted_2 = { class: "description" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "content" }
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString($setup.title), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString($setup.description), 1),
      (!$setup.chs)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "start",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.start && $setup.start(...args)))
          }, "Start"))
        : (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "start",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.start && $setup.start(...args)))
          }, "开始"))
    ]),
    _createElementVNode("img", {
      src: $setup.currentImage,
      class: "flow-image loading",
      alt: "homepage",
      ref: "image"
    }, null, 8, _hoisted_5)
  ]))
}