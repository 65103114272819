<template>
  <div class="copyright">
    <div class="nbc-logo" />
    <span v-if="year === 2024">{{ institution }} © 2024</span>
    <span v-else>{{ institution }} © 2024-{{ year }}</span>
  </div>
</template>

<script lang="ts">
import { computed } from 'vue'
import { useRootStore } from '@/stores/store'
import { storeToRefs } from 'pinia'

export default {
  name: 'FootCopyrightView',
  setup () {
    const year = computed(() => {
      const date = new Date()
      return date.getFullYear()
    })

    const root = useRootStore()
    const { chs } = storeToRefs(root)
    const institution = computed(() => {
      if (chs.value) {
        return '国民核生化灾害防护国家重点实验室 版权所有'
      }
      return 'State Key Laboratory of NBC Protection for Civilian Copyright'
    })

    return {
      year,
      institution
    }
  }
}
</script>

<style scoped>

.copyright {
  background-color: #371417;
  display: flex;
  align-items: center;
  /* font-size: 12px; */
  flex-direction: row;
  justify-content: center;
}

.nbc-logo {
  width: 2rem;
  height: 2rem;
  background-image: url("../../assets/images/nbc_logo.webp");
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 1rem;
}

.copyright span{
  font-size: 0.8rem;
  color: #f7f5f4;
  font-weight: 200;
}

</style>
