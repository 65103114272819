<template>
  <div class="foot">
    <div class="align">
      <div class="my-container">
        <FootAboutView class="component"/>
        <FootToolsView class="component"/>
      </div>
      <FootMapView class="component"/>
    </div>
    <FootCopyrightView />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import FootAboutView from '@/components/FootView/FootAboutView.vue'
import FootToolsView from '@/components/FootView/FootToolsView.vue'
import FootMapView from '@/components/FootView/FootMapView.vue'
import FootCopyrightView from '@/components/FootView/FootCopyrightView.vue'

export default defineComponent({
  name: 'FootIndexView',
  components: { FootAboutView, FootToolsView, FootMapView, FootCopyrightView }

})
</script>

<style scoped>

.foot {
  background-color: #621119;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.foot .my-container {
  display: flex;
  justify-content: space-around;
  color: aliceblue;
  align-items: flex-start;
  width: 89%;
  margin: auto auto 2vh;
}

.align {
  display: flex;
  align-items: center;
  width: 90%;
  justify-content: center;
}

.component {
  height: 80%;
}

</style>
