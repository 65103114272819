import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView/HomeIndexView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/server',
    name: 'server',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "server" */ '../views/ServerView/ServerIndexView.vue')
  },
  {
    path: '/documentation',
    name: 'documentation',
    component: () => import(/* webpackChunkName: "documentation" */ '../views/DocumentationView/DocumentationViewIndex.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "documentation" */ '../views/ContactView/ContactView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  linkActiveClass: 'active',
  scrollBehavior (to, from, savedPosition) {
    return { top: 0 }
  }
})

export default router
