import { defineStore } from 'pinia'

export const useInputFormStore = defineStore('inputform', {
  state: () => {
    return {
      smiles: '',
      exampleSmiles: 'NS(=O)(=O)c1cc2c(cc1Cl)NC(CSCc1ccccc1)=NS2(=O)=O',
      smilesEnable: true,
      fileEnable: false,
      fileSelected: false,
      fileName: 'no file selected',
      file: null as unknown as File,
      numMol: 0,
      maxMol: 50,
      numConf: 10,
      maxConf: 50,
      validSmiles: true,
      nAtoms: 1,
      maxAtoms: 60
    }
  },
  getters: {
    validNumConf (state) {
      const regex = /^[1-9]\d*$/
      if (regex.test(state.numConf.toString())) {
        return state.numConf <= state.maxConf
      }
      return false
    },
    validFile (state) {
      return !!state.file && state.numMol <= state.maxMol
    },
    allowSubmit (): boolean {
      if (this.validNumConf) {
        if (this.fileEnable) {
          return this.validFile
        }
        return !!this.smiles
      }
      return false
    }
  },
  actions: {
    fillSmilesWithExample () {
      this.smiles = this.exampleSmiles
    }
  }
})
