import { defineStore } from 'pinia'
import service from '@/stores/debounce'
import { useBrowseStore } from '@/stores/modules/browseStore'
import { useCssControllerStore } from '@/stores/modules/cssControlerStore'
import { useInputFormStore } from '@/stores/modules/inputFormStore'

let rootUrl: string

if (process.env.NODE_ENV === 'development') {
  rootUrl = 'http://10.1.1.100:5555'
} else {
  rootUrl = `https://${window.location.host}/api`
}

interface ConfResult {
  nValidMol: number,
  names: string[]
  confs: string[][],
  energies: number[][],
  feats: number[][],
}

export const useRootStore = defineStore('root', {
  state: () => {
    return {
      status: 'standby',
      rdkitLoaded: false,
      chs: false
    }
  },
  actions: {
    async getSmilesConformers (smiles: string, nConfs: number, lang: string) {
      const resp = await service({
        url: `${rootUrl}/predict/smiles/conf`,
        method: 'POST',
        data: { smiles, nConfs, lang }
      })
      return resp.data as ConfResult
    },
    async getFileConformers (nConfs: number, lang: string) {
      const inputForm = useInputFormStore()
      const resp = await service({
        url: `${rootUrl}/predict/file/conf`,
        method: 'POST',
        data: {
          file: inputForm.file
        },
        params: { nConfs, lang },
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      return resp.data as ConfResult
    },
    async getConformers (smiles: string, nConfs: number) {
      const cssController = useCssControllerStore()
      const inputForm = useInputFormStore()

      cssController.stepTwoStyle = 'idle'
      this.status = 'running'

      const lang = !this.chs ? 'eng' : 'chs'

      let data = null as unknown as ConfResult
      if (!inputForm.fileEnable) {
        data = await this.getSmilesConformers(smiles, nConfs, lang)
      } else {
        data = await this.getFileConformers(nConfs, lang)
      }

      if (data) {
        const browse = useBrowseStore()
        if (data.nValidMol) {
          browse.nMols = data.nValidMol
          browse.nConfs = nConfs
          browse.sdfArray = data.confs
          browse.updateDownloadQueue()
          browse.names = data.names
          browse.energies = data.energies
          browse.feats = data.feats
        } else {
          browse.nMols = 1
          browse.nConfs = 0
          browse.sdfArray = [['']]
          browse.updateDownloadQueue()
          browse.names = ['Invalid']
          browse.energies = [[Number.NaN]]
        }
      }
      cssController.stepTwoStyle = 'filled'
      this.status = 'finish'
    },
    async download (data: string, ext: string, idx: string) {
      const filename = `${idx}.${ext}`

      const url = window.URL.createObjectURL(new Blob([data]))
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.setAttribute('download', filename)
      document.body.appendChild(a)
      await a.click()
      await window.URL.revokeObjectURL(a.href)
      await document.body.removeChild(a)
    },
    async downloadExample () {
      const resp = await service({
        url: `${rootUrl}/download/example`,
        method: 'POST',
        responseType: 'arraybuffer'
      })
      const data = resp.data
      if (!data) {
        return
      }
      await this.download(data, 'smi', `${!this.chs ? 'example' : '示例'}`)
    },
    async downloadResults () {
      const browse = useBrowseStore()
      const out = []

      for (let i = 0; i < browse.nMols; i++) {
        for (let j = 0; j < browse.nConfs; j++) {
          if (browse.downloadQueue[i][j]) {
            const tmpSDF = browse.sdfArray[i][j]
            const name = `> <CONF_ID>\n${browse.names[i]}-${j + 1}\n\n`
            const energy = `> <MMFF94_Energy>\n${browse.energies[i][j]}\n\n`
            out.push(tmpSDF.concat(name, energy))
          }
        }
      }

      const timeStamp = new Date().getTime()
      await this.download(out.join('$$$$\n'), 'sdf', `${!this.chs ? 'conformers' : '构象'}${timeStamp}`)
    }
  }
})
