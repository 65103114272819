import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-50ffb1ac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tools" }
const _hoisted_2 = { class: "col-auto" }
const _hoisted_3 = ["href"]
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h5", null, _toDisplayString(`${ !_ctx.chs ? 'DEV TOOLS' : '开发工具'}`), 1),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.imgs, (img) => {
        return (_openBlock(), _createElementBlock("a", {
          href: img.href,
          target: "_blank",
          key: img.key
        }, [
          _createElementVNode("img", {
            class: "img-thumbnail",
            src: require(`../../assets/images/tools/${img.src}`),
            alt: ""
          }, null, 8, _hoisted_4)
        ], 8, _hoisted_3))
      }), 128))
    ])
  ]))
}