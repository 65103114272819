<template>
  <component :is="'script'" type="text/javascript" src="//rf.revolvermaps.com/0/0/4.js?i=5ntd4imil3w&amp;m=0&amp;h=128&amp;c=ff0000&amp;r=25" async="async" />
</template>

<script lang="ts">
export default {
  name: 'FootMapView'
}
</script>

<style scoped>

a {
  margin-top: 3.5rem;
}

</style>
