import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-3623a439"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "about" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h5", null, _toDisplayString(`${ !_ctx.chs ? 'About' : '简介'}`), 1),
    _createElementVNode("p", {
      class: "description",
      innerHTML: _ctx.description
    }, null, 8, _hoisted_2)
  ]))
}