import { defineStore } from 'pinia'

export const useCssControllerStore = defineStore('controller', {
  state: () => {
    return {
      stepOneStyle: 'filled',
      stepTwoStyle: 'idle'
    }
  }
})
