<template>
  <div class="background">
    <div id="welcome">
      <HomeDescriptionView/>
    </div>
  </div>
</template>

<script lang="ts">
import HomeDescriptionView from '@/views/HomeView/HomeDescriptionView.vue'

export default {
  name: 'HomeViewIndex',
  components: { HomeDescriptionView }

}
</script>

<style scoped>

#welcome {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.background {
  background-image: url("../../assets/images/home-background.webp");
  border-bottom: 5vh solid transparent;
}

</style>
