import { defineStore } from 'pinia'

export const useBrowseStore = defineStore('browse', {
  state: () => {
    return {
      sdfArray: [[]] as string[][],
      currentPage: 1,
      currentMol: 0,
      nConfs: 10,
      nMols: 1,
      numItemPerPage: 6,
      showOverlap: false,
      showCluster: false,
      downloadQueue: new Array(1).fill(new Array(10).fill(false)) as boolean[][],
      names: [] as string[],
      energies: [[]] as number[][],
      feats: [[]] as number[][]
    }
  },
  actions: {
    addPage () {
      if (this.currentPage === this.totalPage) {
        return
      }
      this.currentPage += 1
    },
    minusPage () {
      if (this.currentPage === 1) {
        return
      }
      this.currentPage -= 1
    },
    updateDownloadQueue () {
      this.downloadQueue = []
      for (let i = 0; i < this.nMols; i++) {
        const tmp = []
        this.molSelectQueue.push(false)
        for (let j = 0; j < this.nConfs; j++) {
          tmp.push(false)
        }
        this.downloadQueue.push(tmp)
      }
    }
  },
  getters: {
    totalPage (state) {
      return Math.ceil(state.nConfs / state.numItemPerPage)
    },
    pageArray (state): Array<number> {
      function range (start: number, stop: number, step = 1): Array<number> {
        return Array(Math.ceil((stop - start) / step)).fill(start).map((x, y) => x + y * step)
      }

      if (this.totalPage <= 5) {
        return range(1, this.totalPage + 1)
      }

      if (state.currentPage - 2 < 1) {
        return range(1, 6)
      }

      if (state.currentPage + 2 > this.totalPage) {
        return range(this.totalPage - 4, this.totalPage + 1)
      }

      return range(state.currentPage - 2, state.currentPage + 3)
    },
    molSelectQueue (state) {
      const out = []
      for (let i = 0; i < state.nMols; i++) {
        const tmp = state.downloadQueue[i]
        if (tmp) {
          out.push(tmp.every(elem => elem))
          continue
        }
        out.push(false)
      }
      return out
    },
    anyConfSelected (state) {
      return state.downloadQueue.some(elem => elem.some(e => e))
    },
    anyCurrentMolConfSelected (state) {
      return state.downloadQueue[state.currentMol].some(elem => elem)
    },
    allConfSelected (state) {
      return [state.downloadQueue.every(elem => elem.every(e => e))]
    },
    totalItems (state) {
      return state.nConfs * state.nMols
    }
  }
})
