<template>
<div class="tools">
  <h5>
    {{ `${ !chs ? 'DEV TOOLS' : '开发工具'}` }}
  </h5>
  <div class="col-auto">
    <a v-for="img in imgs" :href="img.href" target="_blank" :key="img.key">
      <img
        class="img-thumbnail"
        :src="require(`../../assets/images/tools/${img.src}`)"
        alt=""
      />
    </a>
  </div>
</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useRootStore } from '@/stores/store'
import { storeToRefs } from 'pinia'

export default defineComponent({
  name: 'FootToolsView',
  setup () {
    const imgs = [
      { src: 'torch.svg', href: 'https://pytorch.org/', key: 676 },
      { src: 'vue.svg', href: 'https://github.com/vuejs/vue', key: 503 },
      { src: 'fastapi.svg', href: 'https://github.com/tiangolo/fastapi', key: 312 }
    ]

    const root = useRootStore()
    const { chs } = storeToRefs(root)

    return {
      imgs,
      chs
    }
  }
})
</script>

<style scoped>

h5 {
  font-size: 1.2rem;
}

img {
  height: 45px;
  background-color: transparent;
  border: none !important;
}

</style>
