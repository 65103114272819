<template>
  <div class="about">
    <h5>
      {{ `${ !chs ? 'About' : '简介'}` }}
    </h5>
    <p class="description" v-html="description" />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { useRootStore } from '@/stores/store'
import { storeToRefs } from 'pinia'

export default defineComponent({
  name: 'FootAboutView',
  setup () {
    const root = useRootStore()
    const { chs } = storeToRefs(root)

    const description = computed(() => {
      if (chs.value) {
        return 'Conf-GEM是一个针对分子稳定3D构象快速、高质量生成的专业软件，生成的构象无需进一步使用力场优化，可满足用户大规模分子合理稳定3D' +
            '构象生成的需求，支持单个及批量分子文件上传和下载。'
      }
      return 'Conf-GEM is a professional software for rapid and high-quality generation of molecular stable 3D ' +
          'conformations. The generated conformations do not require further force field optimization, ' +
          'and can meet the needs of users for rational and stable 3D conformation generation of large-scale' +
          ' molecules. It supports uploading and downloading of single and batch molecular files. ' +
          'For more details, please refer to our ' +
          '<a href="https://www.sciencedirect.com/science/article/pii/S2949747724000320" style="color: #818078">paper</a>.'
    })

    return {
      description,
      chs
    }
  }
})
</script>

<style scoped>

h5 {
  font-size: 1.2rem;
  font-family: Source Han Sans CN-Bold, serif;
  font-weight: bold;
}

.about {
  max-width: 40vw;
}

.about p {
  font-size: 1rem;
  font-family: Source Han Sans CN-Regular, serif;
}

.description {
  text-align: justify;
}

</style>
