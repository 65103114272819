<template>
  <div class="align">
    <label class="toggle">
      <input class="toggle-checkbox" type="checkbox" v-model="chs">
      <div class="toggle-switch" />
    </label>
    <span class="toggle-label" v-if="!chs">中文</span>
    <span class="toggle-label" v-else>CHS</span>
  </div>
</template>

<script lang="ts">
import { useRootStore } from '@/stores/store'
import { storeToRefs } from 'pinia'

export default {
  name: 'HeaderSwitchesView',
  setup () {
    const root = useRootStore()
    const { chs } = storeToRefs(root)

    return {
      chs
    }
  }
}
</script>

<style scoped>

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  font-family: -apple-system, ".SFNSText-Regular", "Helvetica Neue", "Roboto", "Segoe UI", sans-serif;
}

.toggle {
  cursor: pointer;
  width: 3vw;
  position: relative;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.toggle-switch {
  background: #cccccc;
  width: 100%;
  height: 32px;
  /* position: relative; */
  /* vertical-align: middle; */
  /*transition: background 0.25s;*/
  padding-left: 8%;
  padding-right: 8%;
  display: flex;
  align-items: center;
  transition: 0.25s linear;
}
.toggle-switch:before, .toggle-switch:after {
  content: "";
}
.toggle-switch:before {
  display: block;
  background: linear-gradient(to bottom, #fff 0%, #eee 100%);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25);
  width: 45%;
  height: 75%;
  position: absolute;
  left: 7%;
  transition: 0.2s linear;
}
.toggle:hover .toggle-switch:before {
  background: linear-gradient(to bottom, #fff 0%, #fff 100%);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
}
.toggle-checkbox:checked + .toggle-switch {
  background: #c70707;
}
.toggle-checkbox:checked + .toggle-switch:before {
  /*left:*/
  transform: translateX(93%);
}

.toggle-checkbox {
  position: absolute;
  visibility: hidden;
  width: 100%;
  height: 100%;
  margin: 0;
}

.toggle-label {
  margin-left: 0.5vw;
  position: relative;
  user-select: none;
  font-family: Source Han Sans CN-Regular, serif;
  font-weight: 400;
  font-size: 16px;
  color: #001333;
  text-align: left;
  font-style: normal;
  text-transform: none;
  /*top: 2px;*/
}

.align {
  display: flex;
  align-items: center;
  /*margin-left: 1vw*/
}

</style>
