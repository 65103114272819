import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-24807af6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "foot" }
const _hoisted_2 = { class: "align" }
const _hoisted_3 = { class: "my-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FootAboutView = _resolveComponent("FootAboutView")!
  const _component_FootToolsView = _resolveComponent("FootToolsView")!
  const _component_FootMapView = _resolveComponent("FootMapView")!
  const _component_FootCopyrightView = _resolveComponent("FootCopyrightView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_FootAboutView, { class: "component" }),
        _createVNode(_component_FootToolsView, { class: "component" })
      ]),
      _createVNode(_component_FootMapView, { class: "component" })
    ]),
    _createVNode(_component_FootCopyrightView)
  ]))
}