<template>
  <div id="description-container">
    <div class="description">
      <h1 class="title">{{ title }}</h1>
      <p class="content">{{ description }}</p>
      <div class="start" @click="start" v-if="!chs">Start</div>
      <div class="start" @click="start" v-else>开始</div>
    </div>
    <img :src="currentImage" class="flow-image loading" alt="homepage" ref="image"/>
  </div>
</template>

<script lang="ts">
import { ref, onMounted, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useRootStore } from '@/stores/store'
import { storeToRefs } from 'pinia'

export default {
  name: 'HomeDescriptionView',
  setup () {
    const root = useRootStore()
    const { chs } = storeToRefs(root)

    const title = computed(() => {
      if (chs.value) {
        return '分子构象生成'
      }
      return 'Molecular Conformation Generation'
    })

    const description = computed(() => {
      const wordsENG = 'Conf-GEM is a molecular stable 3D conformation generation model based on pre-trained ' +
          'integrated geometric information assisted enhanced graph neural network. It takes into account both the ' +
          'efficiency and quality of conformation generation, and the generated conformation does not require ' +
          'further force field optimization, achieving the current optimal performance. Furthermore, it can ' +
          'effectively meet the demand for the rapid generation of rational and stable 3D conformations of ' +
          'large-scale molecules. Users can easily use this server to carry out 3D molecular conformation ' +
          'generation tasks based on 1D molecular sequences, with single and batch file upload and download ' +
          'functions. This server can provide high-quality molecular 3D structures for many downstream advanced ' +
          'molecular design tasks, such as molecular property prediction, directed generation, molecular docking, ' +
          'and virtual screening.'

      const wordsCHS = 'Conf-GEM是一款基于预训练融合几何信息辅助增强图神经网络的分子3D稳定构象生成模型。它同时兼顾了构象生成效率和生成质量，' +
          '生成的构象无需进一步使用力场优化，性能达到了当前最优，可满足大规模分子合理稳定3D构象快速生成的需求。' +
          '用户可以使用该服务器很方便的开展基于1D分子序列的3D分子构象生成任务，具备单个及批量文件上传和下载功能。' +
          '该服务器可为分子性质预测、定向生成、分子对接和虚拟筛选等一系列下游高级分子设计任务提供高质量的分子3D结构。'

      if (chs.value) {
        return wordsCHS
      }
      return wordsENG
    })

    const currentImage = ref('https://confgem.cmdrg.com/assets/images/flow-min.png')

    const router = useRouter()
    async function start () {
      await router.push({ name: 'server' })
    }

    const image = ref(null)

    onMounted(() => {
      const webpImage = new Image()
      webpImage.src = 'https://confgem.cmdrg.com/assets/images/flow.webp'
      webpImage.onload = () => {
        const element = image.value as unknown as HTMLElement
        element.classList.remove('loading')
        currentImage.value = webpImage.src
        const gifImage = new Image()
        gifImage.src = 'https://confgem.cmdrg.com/assets/images/flow-min.gif'
        gifImage.onload = () => {
          currentImage.value = gifImage.src
        }
      }
    })

    return {
      title,
      description,
      start,
      currentImage,
      image,
      chs
    }
  }
}
</script>

<style scoped>
#description-container {
  position: absolute;
  top: 95px;
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 95%;
  height: 95%;
  justify-content: space-around;
}

.description {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
}

.description .title {
  font-family: Source Han Sans CN-Bold, serif;
  font-weight: bold;
  font-size: 1.5rem;
  color: #001333;
  line-height: 0;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.description .content {
  font-family: Source Han Sans CN-Regular, serif;
  font-weight: 400;
  font-size: 1rem;
  color: #001333;
  /* line-height: 0px; */
  text-align: justify;
  font-style: normal;
  text-transform: none;
  margin-top: 1.5rem;
}

.flow-image {
  height: 60%;
  width: 40%;
}

.flow-image.loading {
  filter: blur(15px);
  animation: 1.5s infinite flash;
}

@keyframes flash
{
  0% {
    transform:scale(1);
    opacity: 1;
  }

  100% {
    transform:scale(0.95);
    opacity: 0;
  }
}

.description .start {
  width: 30%;
  height: 40px;
  background: #A81726;
  border-radius: 4px 4px 4px 4px;
  font-family: Source Han Sans CN-Regular, serif;
  font-weight: 400;
  font-size: 16px;
  color: #FFFFFF;
  line-height: 0px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 40px;
  user-select: none;
}
</style>
