<template>
  <teleport to="#app">
    <div id="mask">
      <slot name="content" />
    </div>
  </teleport>
</template>

<script>
export default {
  name: 'MaskView'
}
</script>

<style scoped>

#mask {
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 1000;
}

</style>
